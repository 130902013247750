import React, { FC, useEffect, useState } from "react";
import Logo from "shared/Logo/Logo";
import MenuBar from "shared/MenuBar/MenuBar";
import LangDropdown from "./LangDropdown";
import AvatarDropdown from "./AvatarDropdown";
import TemplatesDropdown from "./TemplatesDropdown";
import DropdownCategories from "./DropdownCategories";
import CartDropdown from "./CartDropdown";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { useWindowSize } from "react-use";
import CustomDialog from "components/Dialog/Dialog";
import LoginForm2 from "components/Login/LoginForm2";

export interface MainNav2Props {
  className?: string;
}

const MainNav2: FC<MainNav2Props> = ({ className = "" }) => {
  const [showSearchForm, setShowSearchForm] = useState(false);
  const [searchParams, setSearchParams] = useState<any>('');
  const [isMobile, setIsMobile] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const navigate = useNavigate();
  const windowSize = useWindowSize();

  let searchP = localStorage.getItem('searchInput');
  let pathname = window.location.pathname.split('/').filter(item => item !== '');

  useEffect(() => {
    setIsMobile((prevState) => {
      if (!prevState && windowSize.width < 768) {
        return true;
      } else if (prevState && windowSize.width > 768) {
        return false;
      } else {
        return prevState;
      }
    });
  }, [windowSize]);

  const renderMagnifyingGlassIcon = () => {
    return (
      <svg
        width={22}
        height={22}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22 22L20 20"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };

  const renderSearchForm = () => {
    return (
      <form
        className="flex-1 py-2 text-slate-900 dark:text-slate-100"
        onSubmit={(e) => {
          e.preventDefault();
          localStorage.setItem('searchInput', searchParams);
          navigate("/page-search");
        }}
      >
        <div className="bg-slate-50 dark:bg-slate-800 flex items-center space-x-1.5 px-5 h-full rounded">
          {renderMagnifyingGlassIcon()}
          <input
            type="search"
            placeholder="pièces recherchées..."
            onChange={(e: any) => setSearchParams(e.target.value)}
            className="border-none bg-transparent focus:outline-none focus:ring-0 w-full text-base"
            autoFocus
          />
        </div>
        <input type="submit" hidden value="" />
      </form>
    );
  };

  return (
    <div className="nc-MainNav2Logged relative z-10 bg-white dark:bg-slate-900">
      <div className="container">
        <div className="h-20 flex justify-between items-center">
          {/* MenuBar on mobile */}
          <div className="flex items-center md:hidden flex-1">
            <MenuBar />
          </div>

          {/* Logo and Category Dropdown */}
          <div className="flex lg:flex-1 items-center space-x-3 mt-2 sm:space-x-8 w-full justify-between">
            <Logo />
            {!showSearchForm && (
              <div className="hidden md:block h-10 border-l border-slate-200 dark:border-slate-700"></div>
            )}
            {!showSearchForm && (
              <div className="hidden md:block">
                <DropdownCategories />
              </div>
            )}
          </div>

          {/* Search Form */}
          {!isMobile && pathname[0] !== 'page-search' && (
            <div className="flex-[1.5] flex !mx-auto px-10">
              {renderSearchForm()}
            </div>
          )}

          {/* Right Section (Login Button, Language Dropdown, Cart) */}
          <div className="flex items-center justify-end space-x-4">
            <LangDropdown />
            <button
              onClick={() => setIsDialogOpen(true)}
              className="px-6 h-12 bg-[#547cb4] text-white font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75"
            >
              Se connecter
            </button>
            <CartDropdown />
          </div>
        </div>
      </div>

      {/* Login Form Dialog */}
      {isDialogOpen && <LoginForm2 isDialogOpen={isDialogOpen} setIsDialogOpen={setIsDialogOpen} />}
    </div>
  );
};

export default MainNav2;
