import CardCategory1 from "components/CardCategories/CardCategory1";
import CardCategory4 from "components/CardCategories/CardCategory4";
import Heading from "components/Heading/Heading";
import NavItem2 from "components/NavItem2";
import React, { FC } from "react";
import Nav from "shared/Nav/Nav";
import explore1Png from "images/collections/explore1.png";
import explore2Png from "images/collections/explore2.png";
import explore3Png from "images/collections/explore3.png";
import explore4Png from "images/collections/explore4.png";
import explore5Png from "images/collections/explore5.png";
import explore6Png from "images/collections/explore6.png";
import explore7Png from "images/collections/explore7.png";
import explore8Png from "images/collections/explore8.png";
import explore9Png from "images/collections/explore9.png";
import { GiMechaHead, GiElectric, GiAutomaticSas, GiStaplerPneumatic } from "react-icons/gi";
import { FcElectronics } from "react-icons/fc";
import NotFound from "containers/NotFound/NotFound";

interface ExploreType {
  id: number;
  name: string;
  desc: string;
  image: string;
  svgBg: string;
  color?: string;
}

export const DEMO_MORE_EXPLORE_DATA = [
  {
    id: 1,
    name: "Backpack",
    desc: "Manufacturer",
    image: explore1Png,
    svgBg: "bg-indigo-50",
    color: "bg-indigo-50",
  },
  {
    id: 2,
    name: "Shoes",
    desc: "Manufacturer",
    image: explore2Png,
    svgBg: "bg-slate-100/80",
    color: "bg-slate-100/80",
  },
  {
    id: 3,
    name: "Recycled Blanket",
    desc: "Manufacturer",
    image: explore3Png,
    svgBg: "bg-violet-50",
    color: "bg-violet-50",
  },
  {
    id: 4,
    name: "Cycling Shorts",
    desc: "Manufacturer",
    image: explore9Png,
    svgBg: "bg-orange-50",
    color: "bg-orange-50",
  },
  {
    id: 5,
    name: "Cycling Jersey",
    desc: "Manufacturer",
    image: explore5Png,
    svgBg: "bg-blue-50",
    color: "bg-blue-50",
  },
  {
    id: 6,
    name: "Car Coat",
    desc: "Manufacturer",
    image: explore6Png,
    svgBg: "bg-orange-50",
    color: "bg-orange-50",
  },
];

const TenderCall: FC = ({ className = "" }: any) => {
  const [tabActive, setTabActive] = React.useState("Man");

  const renderCard = (item: ExploreType) => {
    return (
      <CardCategory4
        key={item.id}
        name={item.name}
        desc={item.desc}
        bgSVG={item.svgBg}
        featuredImage={item.image}
        color={item.color}
      />
    );
  };

  const renderHeading = () => {
    return (
      <div className="text-center mb-12 lg:mb-14">
        <Heading
          fontClass="text-4xl md:text-5xl 2xl:text-6xl font-extrabold text-gradient"
          isCenter
          desc="Explorez nos appels d'offre"
        >
          Nos Appels D'offre
        </Heading>
        <Nav
          className="p-3 bg-gradient-to-r from-blue-500 to-teal-500 rounded-full shadow-xl overflow-x-auto hiddenScrollbar"
          containerClassName="mb-12 lg:mb-14 relative flex justify-center w-full"
        >
          {[
            { name: "Mecanique", icon: <GiMechaHead /> },
            { name: "Electronique", icon: <FcElectronics /> },
            { name: "Electrique", icon: <GiElectric /> },
            { name: "Automatisme", icon: <GiAutomaticSas /> },
            { name: "Pneumatique", icon: <GiStaplerPneumatic /> },
          ].map((item, index) => (
            <NavItem2
              key={index}
              isActive={tabActive === item.name}
              onClick={() => setTabActive(item.name)}
            >
              <div className="flex items-center justify-center space-x-2 text-white hover:text-yellow-400 transition-all">
                <span className="text-2xl">{item.icon}</span>
                <span>{item.name}</span>
              </div>
            </NavItem2>
          ))}
        </Nav>
      </div>
    );
  };

  return (
    <div className={`container mb-10 mt-10 ${className}`} data-nc-id="TenderCall">
      {renderHeading()}
      {/* <div className="grid gap-6 md:gap-8 grid-cols-1 md:grid-cols-2 xl:grid-cols-3">
        {DEMO_MORE_EXPLORE_DATA.map(renderCard)}
      </div> */}
      <div className="my-0 h-[420px] mt-4">
        <NotFound
          innerWrapperClassNames="h-[420px] bg-gradient-to-r from-red-500 to-orange-500 text-white"
          innerText="Aucune donnée trouvée pour ce critère."
        />
      </div>
    </div>
  );
};

export default TenderCall;
