import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';  // Import useNavigate
import banner from "images/banner.png";
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import Heading from 'components/Heading/Heading';

const Aboutus = () => {
    const navigate = useNavigate();  // Initialize useNavigate

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }, []);

    // Function to handle navigation
    const handleContactUsClick = () => {
        navigate('/contactus');  // Navigate to the "contactus" page
    };

    return (
        <div className="container min-h-screen p-8 bg-neutral-50">
            {/* Banner Section */}
            <div className="relative">
                <img src={banner} alt="Banner" className="w-full h-60 object-cover rounded-xl shadow-lg" />
                <div className="absolute inset-0 flex justify-center items-center bg-black bg-opacity-50">
                    <h1 className="text-3xl text-white font-bold">A propos de NOUS</h1>
                </div>
            </div>

            {/* Heading Section */}
            <Heading
                className="mt-12 lg:mt-16 mb-12 lg:mb-14 text-neutral-900 dark:text-neutral-50"
                fontClass="text-3xl md:text-4xl 2xl:text-5xl font-semibold"
                isCenter
                desc=""
            >
                En savoir plus sur notre mission et vision
            </Heading>

            {/* Content Section */}
            <div className="space-y-8 md:space-y-12">
                <div className='p-8 md:p-16 font-poppins text-lg text-neutral-700'>
                    <p>Factoconn est un espace dédié aux entreprises du domaine industriel pour leur permettre de se connecter. Factoconn vient aider les professionnels du monde industriel (responsables maintenance, production, directeurs achats, ...) à résoudre leurs problèmes quotidiens.</p>
                </div>

                {/* Mission & Vision Cards */}
                <div className="flex flex-col space-y-8 md:space-y-0 md:grid md:grid-cols-2 md:gap-8">
                    <div className="border p-6 rounded-lg bg-white shadow-xl hover:shadow-2xl transform hover:scale-105 transition-transform duration-300 ease-in-out hover:cursor-pointer">
                        <div className="border-b pb-2 mb-4">
                            <h2 className="text-xl font-semibold text-neutral-800">Notre Mission</h2>
                        </div>
                        <div className="text-gray-700">
                            <p>Chez Factoconn, nous connectons les professionnels du secteur industriel pour résoudre leurs défis quotidiens en leur offrant un accès simplifié aux pièces de rechange, consommables, équipements, formations, et prestataires de services essentiels. Nous contribuons à la réduction des pertes de production et à l'optimisation des stocks, tout en favorisant l'innovation et la pérennité des entreprises industrielles.</p>
                        </div>
                    </div>

                    <div className="border p-6 rounded-lg bg-white shadow-xl hover:shadow-2xl transform hover:scale-105 transition-transform duration-300 ease-in-out hover:cursor-pointer">
                        <div className="border-b pb-2 mb-4">
                            <h2 className="text-xl font-semibold text-neutral-800">Notre Vision</h2>
                        </div>
                        <div className="text-gray-700">
                            <p>Devenir la plateforme industrielle de référence, facilitant l'accès aux ressources, services et solutions indispensables pour optimiser la performance des entreprises industrielles à travers le monde.</p>
                        </div>
                    </div>
                </div>

                {/* Contact Us Button */}
                <div className="flex justify-center items-center text-center mt-12 lg:mt-16">
                    <ButtonPrimary
                        type="button"
                        className="lg:w-[335px] w-[200px] lg:h-[60px] h-[46px] bg-blue-600 hover:bg-blue-700 transition-colors border-none rounded-full text-white flex items-center justify-center space-x-2 shadow-lg hover:shadow-xl duration-300"
                        onClick={handleContactUsClick}  // Use onClick for navigation
                    >
                        <span className="font-semibold text-lg">Nous Contacter</span>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="ml-2">
                            <path d="M14.4301 18.819C14.3316 18.8194 14.2339 18.8001 14.1429 18.7623C14.0519 18.7245 13.9694 18.669 13.9001 18.599C13.7606 18.4579 13.6824 18.2674 13.6824 18.069C13.6824 17.8706 13.7606 17.6801 13.9001 17.539L19.4401 11.999L13.9001 6.45901C13.7703 6.31636 13.7005 6.12921 13.7051 5.93642C13.7097 5.74364 13.7884 5.56003 13.9248 5.42374C14.0612 5.28744 14.2449 5.20893 14.4377 5.20451C14.6305 5.20009 14.8175 5.27011 14.9601 5.40001L21.0301 11.47C21.1696 11.6111 21.2478 11.8016 21.2478 12C21.2478 12.1984 21.1696 12.3889 21.0301 12.53L14.9601 18.6C14.8907 18.6698 14.8081 18.7252 14.7171 18.7628C14.6261 18.8004 14.5285 18.8195 14.4301 18.819Z" fill="#fff" />
                            <path d="M20.33 12.75H3.5C3.30149 12.7487 3.11149 12.6693 2.97112 12.5289C2.83075 12.3885 2.75131 12.1985 2.75 12C2.75131 11.8015 2.83075 11.6115 2.97112 11.4711C3.11149 11.3307 3.30149 11.2513 3.5 11.25H20.33C20.5285 11.2513 20.7185 11.3307 20.8589 11.4711C20.9993 11.6115 21.0787 11.8015 21.08 12C21.0787 12.1985 20.9993 12.3885 20.8589 12.5289C20.7185 12.6693 20.5285 12.7487 20.33 12.75Z" fill="#fff" />
                        </svg>
                    </ButtonPrimary>
                </div>
            </div>
        </div>
    );
};

export default Aboutus;
