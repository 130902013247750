import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import appeldoffre from "images/appeldoffre.png";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Logo from "shared/Logo/Logo";
import backgroundLineSvg from "images/Moon.svg";

// Ensure this section is only visible on the homepage, can be managed using the page's context or routing
export interface SectionPromo2Props {
  className?: string;
}

const SectionPromo2: FC<SectionPromo2Props> = ({ className = "lg:pt-10" }) => {
  return (
    <div className={`nc-SectionPromo2 ${className}`}>
      <div className="relative flex flex-col lg:flex-row lg:justify-between bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 dark:bg-gradient-to-r dark:from-indigo-700 dark:via-purple-700 dark:to-pink-700 rounded-2xl sm:rounded-[40px] p-8 sm:p-12 lg:p-16 shadow-xl overflow-hidden">
        
        {/* Background Decoration */}
        <div className="absolute inset-0 -z-10">
          <img
            className="absolute w-full h-full object-cover dark:opacity-20"
            src={backgroundLineSvg}
            alt="backgroundLineSvg"
          />
        </div>

        {/* Content Section */}
        <div className="lg:w-[45%] md:ml-[55%] max-w-lg relative z-10">
          <Logo className="w-28 mb-6" />
          <h2 className="font-semibold text-3xl sm:text-4xl xl:text-5xl 2xl:text-6xl mt-6 sm:mt-10 leading-tight text-white">
            Soumissionner à<br />
            nos appels d'offre
          </h2>
          <span className="block mt-6 text-slate-200 dark:text-slate-300">
            Répondez plus rapidement aux besoins des particuliers en soumissionnant à nos appels d'offres.
          </span>
          <div className="flex space-x-2 sm:space-x-5 mt-6 sm:mt-12">
            <ButtonPrimary
              href="/tender-call"
              className="dark:bg-slate-200 dark:text-slate-900 bg-white text-indigo-600 transition-all duration-300 ease-in-out hover:bg-indigo-600 hover:text-white shadow-lg transform hover:scale-105"
            >
              Soumissionner maintenant!
            </ButtonPrimary>
          </div>
        </div>

        {/* Image Section */}
        <div className="relative mt-10 lg:mt-0 lg:absolute lg:left-0 lg:bottom-0 max-w-xl lg:max-w-[calc(55%-40px)]">
          <NcImage
            containerClassName="transform lg:scale-110 transition-all duration-500 ease-in-out"
            src={appeldoffre}
            alt="Appel d'Offre"
          />
        </div>
      </div>
    </div>
  );
};

export default SectionPromo2;
