import React, { useState, Fragment } from "react";
// @ts-ignore
import { Transition, Dialog } from "@headlessui/react";
import NavMobile from "shared/Navigation/NavMobile";

export interface MenuBarProps {}
const MenuBar: React.FC<MenuBarProps> = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleOpenMenu = () => setIsVisible(true);
  const handleCloseMenu = () => setIsVisible(false);

  const renderContent = () => {
    return (
      <div className="">
        <Transition appear show={isVisible} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-50 overflow-y-auto"
            onClose={handleCloseMenu}
          >
            <div className="fixed left-0 top-0 bottom-0 w-full max-w-md md:w-auto z-max outline-none focus:outline-none">
              <React.Fragment>
                <Transition.Child
                  as={Fragment}
                  enter="transition duration-100 transform"
                  enterFrom="opacity-0 -translate-x-14"
                  enterTo="opacity-100 translate-x-0"
                  leave="transition duration-150 transform"
                  leaveFrom="opacity-100 translate-x-0"
                  leaveTo="opacity-0 -translate-x-14"
                >
                  <div className="z-20 relative">
                    <NavMobile onClickClose={handleCloseMenu} />
                  </div>
                </Transition.Child>

                <Transition.Child
                  as={Fragment}
                  enter="duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  {/* @ts-ignore */}
                  <Dialog.Overlay className="fixed inset-0 bg-neutral-900/60" />
                </Transition.Child>
              </React.Fragment>
            </div>
          </Dialog>
        </Transition>
      </div>
    );
  };

  return (
    <>
      <button
        onClick={() => setIsVisible(!isVisible)}
        className="relative w-10 h-10 flex flex-col justify-center items-center group"
      >
        {/* Top line */}
        <div
          className={`w-8 h-0.5 bg-current transform transition-transform duration-300 ease-in-out ${
            isVisible ? "rotate-45 translate-y-2.5" : ""
          }`}
        />
        {/* Middle line */}
        <div
          className={`w-8 h-0.5 bg-current my-1 transition-opacity duration-300 ease-in-out ${
            isVisible ? "opacity-0" : "opacity-100"
          }`}
        />
        {/* Bottom line */}
        <div
          className={`w-8 h-0.5 bg-current transform transition-transform duration-300 ease-in-out ${
            isVisible ? "-rotate-45 -translate-y-2.5" : ""
          }`}
        />
      </button>

      {renderContent()}
    </>
  );
};

export default MenuBar;
