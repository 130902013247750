import Heading from 'components/Heading/Heading';
import RequestQuoteForm from 'containers/Contactus/RequestQuoteForm';
import React from 'react';

const RequestNeed = () => {
  return (
    <div className="container min-h-screen py-16 lg:py-24">
      {/* Heading Section */}
      <Heading
        className="mb-12 lg:mb-16 text-neutral-900 dark:text-neutral-50"
        fontClass="text-3xl md:text-4xl 2xl:text-5xl font-semibold text-center"
        desc="Exprimez vos besoins en matière de services"
        isCenter
      >
        Exprimer un besoin
      </Heading>

      {/* Form Section */}
      <div className="flex justify-center items-center">
        <div className="w-full max-w-3xl p-6 bg-white shadow-lg rounded-xl border border-gray-200 dark:bg-neutral-800 dark:border-neutral-700">
          <RequestQuoteForm />
        </div>
      </div>
    </div>
  );
};

export default RequestNeed;
