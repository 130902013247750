import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";

const LoginForm2 = ({ isDialogOpen, setIsDialogOpen }: any) => {
  const urlNavigate = useNavigate();
  const factoconnBlue = "#00539B"; // Main blue color from the logo
  const factoconnLightBlue = "#E5F1FB"; // Lighter blue tone for accents

  function closeModal() {
    setIsDialogOpen(false);
  }

  return (
    <div>
      <Transition appear show={isDialogOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/50" />
          </Transition.Child>

          <div className="fixed inset-0 flex items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-4xl rounded-lg bg-white shadow-xl overflow-hidden">
                <div className="flex flex-col md:flex-row">
                  {/* Left Section */}
                  <div
                    className="md:w-1/2 w-full p-8 flex flex-col justify-between text-center md:text-left"
                    style={{ backgroundColor: factoconnBlue }}
                  >
                    <div>
                      <h1 className="text-3xl md:text-4xl font-bold text-white">
                        Bienvenue !
                      </h1>
                      <p className="mt-2 text-white text-sm md:text-lg">
                        Connectez-vous pour continuer
                      </p>
                    </div>
                    <button
                      className="text-white font-semibold py-3 px-6 rounded-lg mt-6 md:mt-auto"
                      style={{ backgroundColor: factoconnLightBlue, color: factoconnBlue }}
                    >
                      Créer un compte
                    </button>
                  </div>

                  {/* Right Section */}
                  <div className="md:w-1/2 w-full p-6 md:p-8">
                    <Dialog.Title
                      className="text-xl md:text-2xl font-semibold"
                      style={{ color: factoconnBlue }}
                    >
                      Connectez-vous en un clic
                    </Dialog.Title>

                    {/* Social Media Buttons */}
                    <div className="flex flex-col md:flex-row gap-4 mt-6">
                      <button
                        className="flex-1 py-2 rounded-lg font-medium hover:opacity-90"
                        style={{
                          backgroundColor: factoconnLightBlue,
                          color: factoconnBlue,
                        }}
                      >
                        Google
                      </button>
                      <button
                        className="flex-1 py-2 rounded-lg font-medium hover:opacity-90"
                        style={{
                          backgroundColor: factoconnLightBlue,
                          color: factoconnBlue,
                        }}
                      >
                        Facebook
                      </button>
                    </div>
                    <p className="text-center text-gray-400 mt-4">ou</p>

                    {/* Login Form */}
                    <form className="mt-6 space-y-4">
                      <div>
                        <label
                          htmlFor="email"
                          className="block font-medium mb-1"
                          style={{ color: factoconnBlue }}
                        >
                          Adresse email ou utilisateur
                        </label>
                        <input
                          type="email"
                          id="email"
                          className="w-full border rounded-lg px-4 py-2 focus:outline-none focus:ring"
                          style={{
                            borderColor: factoconnBlue,
                            color: factoconnBlue,
                          }}
                          placeholder="Entrez votre email"
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="password"
                          className="block font-medium mb-1"
                          style={{ color: factoconnBlue }}
                        >
                          Mot de passe
                        </label>
                        <input
                          type="password"
                          id="password"
                          className="w-full border rounded-lg px-4 py-2 focus:outline-none focus:ring"
                          style={{
                            borderColor: factoconnBlue,
                            color: factoconnBlue,
                          }}
                          placeholder="Entrez votre mot de passe"
                        />
                      </div>
                      <div className="flex items-center justify-between">
                        <div className="flex items-center">
                          <input
                            type="checkbox"
                            id="remember"
                            className="mr-2"
                            style={{ accentColor: factoconnBlue }}
                          />
                          <label
                            htmlFor="remember"
                            className="text-sm"
                            style={{ color: factoconnBlue }}
                          >
                            Se souvenir de moi
                          </label>
                        </div>
                        <a
                          href="#"
                          className="text-sm hover:underline"
                          style={{ color: factoconnBlue }}
                        >
                          Mot de passe oublié ?
                        </a>
                      </div>
                      <button
                        onClick={() => urlNavigate("/dashboard")}
                        className="w-full mt-4 py-2 rounded-lg font-semibold hover:opacity-90"
                        style={{
                          backgroundColor: factoconnBlue,
                          color: "white",
                        }}
                      >
                        Connexion
                      </button>
                    </form>

                    {/* Footer */}
                    <div className="mt-6 text-center text-sm">
                      <a
                        href="#"
                        className="hover:underline"
                        style={{ color: factoconnBlue }}
                      >
                        Conditions d'utilisation
                      </a>{" "}
                      -{" "}
                      <a
                        href="#"
                        className="hover:underline"
                        style={{ color: factoconnBlue }}
                      >
                        Politique de confidentialité
                      </a>{" "}
                      -{" "}
                      <a
                        href="#"
                        className="hover:underline"
                        style={{ color: factoconnBlue }}
                      >
                        Politique des cookies
                      </a>
                    </div>
                  </div>
                </div>

                {/* Close Button */}
                <button
                  onClick={closeModal}
                  className="absolute top-4 right-4 hover:opacity-80"
                  style={{ color: factoconnBlue }}
                >
                  ✖
                </button>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default LoginForm2;
