import React, { useEffect } from "react";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionSliderLargeProduct from "components/SectionSliderLargeProduct";
import SectionSliderProductCard from "components/SectionSliderProductCard";
import SectionGridMoreExplore, {
  DEMO_MORE_EXPLORE_DATA,
} from "components/SectionGridMoreExplore/SectionGridMoreExplore";
import SectionPromo2 from "components/SectionPromo2";
import SectionGridFeatureItems from "./SectionGridFeatureItems";
import SectionHero3 from "components/SectionHero/SectionHero3";
import SectionPromo1 from "components/SectionPromo1";
import { SPORT_PRODUCTS } from "data/data";
import SectionHero2 from "components/SectionHero/SectionHero2";
import FAQ from "components/FAQ/FAQ";
import Membership from "components/membership/membership";
// @ts-ignore
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { activeSubCategory } from "store/actions";
import { useDispatch } from "react-redux";


function PageHome2() {

  const dispacth = useDispatch();

  useEffect(() => {

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });

    dispacth(
      activeSubCategory(null)
    );
  }, []);

  return (
    <div className="nc-PageHome2 relative overflow-hidden mt-12">
      <div className="container px-4">
        {/* SECTION HERO */}
        <SectionHero3 />
      </div>

      <div className="container relative space-y-24 my-24 md:my-36 lg:space-y-32">
        {/* <SectionHowItWork /> */}

        {/* SECTION */}
        <SectionSliderProductCard
          data={SPORT_PRODUCTS.filter((_, i) => i < 6)}
          subHeading="les plus recherchées"
        />

        {/* SECTION */}
        {/* <SectionPromo2 /> */}

        {/* FAQ */}
        {/* <FAQ /> */}

        <SectionPromo2 />
        {/* <div className='mt-20'>
        <MailchimpSubscribe
          url={""}
          render={({ subscribe, status, message }: any) => (
            <Membership
              status={status}
              message={message}
              onValidated={(formData: any) => subscribe(formData)}
            />
          )}
        />
      </div> */}

        {/* SECTION 3 */}
        {/* <SectionSliderLargeProduct /> */}

        {/* SECTION */}
        {/* <div className="relative py-24 lg:py-32">
          <BackgroundSection />
          <SectionGridMoreExplore
            data={DEMO_MORE_EXPLORE_DATA.filter((_, i) => i > 2)}
          />
        </div> */}

        {/* SECTION */}
        {/*  <SectionGridFeatureItems data={SPORT_PRODUCTS} /> */}

        {/* SECTION */}
        {/* <SectionPromo1 /> */}
      </div>
    </div>
  );
}

export default PageHome2;
