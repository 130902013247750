import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import PageHome from "containers/PageHome/PageHome";
import Page404 from "containers/Page404/Page404";
import AccountPage from "containers/AccountPage/AccountPage";
import PageContact from "containers/PageContact/PageContact";
import PageAbout from "containers/PageAbout/PageAbout";
import PageSignUp from "containers/PageSignUp/PageSignUp";
import PageLogin from "containers/PageLogin/PageLogin";
import PageSubcription from "containers/PageSubcription/PageSubcription";
import BlogPage from "containers/BlogPage/BlogPage";
import BlogSingle from "containers/BlogPage/BlogSingle";
import SiteHeader from "containers/SiteHeader";
import PageCollection from "containers/PageCollection";
import PageSearch from "containers/PageSearch";
import PageHome2 from "containers/PageHome/PageHome2";
import PageHome3 from "containers/PageHome/PageHome3";
import ProductDetailPage from "containers/ProductDetailPage/ProductDetailPage";
import ProductDetailPage2 from "containers/ProductDetailPage/ProductDetailPage2";
import AccountSavelists from "containers/AccountPage/AccountSavelists";
import AccountPass from "containers/AccountPage/AccountPass";
import AccountBilling from "containers/AccountPage/AccountBilling";
import AccountOrder from "containers/AccountPage/AccountOrder";
import CartPage from "containers/ProductDetailPage/CartPage";
import CheckoutPage from "containers/PageCheckout/CheckoutPage";
import PageCollection2 from "containers/PageCollection2";
import { Toaster } from "react-hot-toast";
import Footer2 from "shared/Footer/Footer2";
import { Services } from "containers/Services";
import Aboutus from "containers/Aboutus/Aboutus";
import Contact from "containers/Contactus/Contact";
import RequestNeed from "containers/RequestNeed/RequestNeed";
import TenderCall from "containers/TenderCall/TenderCall";
import FaqView from "containers/FAQ/faq";
import Dashboard from "containers/Dashboard/Dashboard";

export const pages: any[] = [
  { path: "/", component: PageHome2 },
  //{ path: "/home2", component: PageHome2 },
  //{ path: "/home3", component: PageHome3 },
  //
  //{ path: "/home-header-2", component: PageHome },
  { path: "/product-detail/:id", component: ProductDetailPage },
  // { path: "/product-detail-2", component: ProductDetailPage2 },
  //
  { path: "/products", component: PageCollection2 },
  { path: "/services", component: Services },
  { path: "/aboutus", component: Aboutus },
  { path: "/contactus", component: Contact },
  { path: "/faq", component: FaqView },

  // { path: "/page-collection", component: PageCollection },
  { path: "/page-search", component: PageSearch },
  { path: "/request-need", component: RequestNeed },
  { path: "/tender-call", component: TenderCall },
  { path: "/dashboard", component: Dashboard },
  //
  // { path: "/account", component: AccountPage },
  // { path: "/account-savelists", component: AccountSavelists },
  // { path: "/account-change-password", component: AccountPass },
  // { path: "/account-billing", component: AccountBilling },
  // { path: "/account-my-order", component: AccountOrder },
  // //
  // { path: "/cart", component: CartPage },
  // { path: "/checkout", component: CheckoutPage },
  // //
  // { path: "/blog", component: BlogPage },
  // { path: "/blog-single", component: BlogSingle },
  // //
  // { path: "/contact", component: PageContact },
  // { path: "/about", component: PageAbout },
  // { path: "/signup", component: PageSignUp },
  // { path: "/login", component: PageLogin },
  // { path: "/subscription", component: PageSubcription },
];

const MyRoutes = () => {
  return (
    <BrowserRouter>
      <Toaster />
      <ScrollToTop />
      <SiteHeader />
      <Routes>
        {pages.map(({ component: Component, path }, index) => {
          return <Route key={index} element={<Component />} path={path} />;
        })}
        <Route element={<Page404 />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default MyRoutes;
